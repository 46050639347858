import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Last Day Of Deload Week`}</em></p>
    <p>{`DB Push Press 12-12-12`}</p>
    <p>{`DB Rows 12-12-12`}</p>
    <p>{`then,`}</p>
    <p>{`21-15-9 reps each of:`}</p>
    <p>{`HSPU’s`}</p>
    <p>{`Pullups`}</p>
    <p>{`9-15-21 reps each of:`}</p>
    <p>{`DB S2OH (40/25’s) (RX+ 50/35’s)`}</p>
    <p>{`Ring Rows`}</p>
    <p>{`for total time.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`This Saturday is our monthly free class at 9:00 and 10:00am so
bring a friend!`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`The next Strength Program starts on 9/23.  For more info email Eric
at Fallscitystrength\\@gmail.com.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      